import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import styled from "styled-components"
import { rhythm, scale } from "../utils/typography"

const ContactWrapper = styled.div`
  text-align: center;
  margin-top: ${rhythm(5)};
  h1 {
    letter-spacing: 0.2em;
    font-size: ${rhythm(2)};
  }
  p {
    font-size: ${rhythm(1)};
  }
`

const Contact = () => {
  return (
    <Layout>
      <ContactWrapper>
        <SEO title="Contact" />
        <p style={{ marginTop: "2em" }}>lukafrance@gmail.com</p>
        <p>United Kingdom</p>
      </ContactWrapper>
    </Layout>
  )
}

export default Contact
